import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import arrow from "../images/arrow.svg"

import introTexts from "../../content/faq/intro_section.json"
import faqs from "../../content/faq/questions.json"

const Faq = () => (
  <Layout pageTitle="FAQ">
    <Seo
      title="FAQ"
      description="Do you have questions about Djäkne Startup Studio? Browse through these FAQs to find answers to commonly raised questions."
      keywords="startup studio malmö, startup, tech startup, tech entrepreneurs, malmö, scaleup, angel investing, faq"
    />
    <section className="contact">
      <div className="container-fluid">
        <div className="container-xxl">
          <div className="row align-items-center ">
            <div className="col-12 d-flex flex-row align-items-center justify-content-center">
              <h1 className="mt-5 mb-3 mb-md-5 ">{introTexts.headline}</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 px-3" id="accordionFlushExample">
                {faqs.questions.map((faq, index) => (
                  <div key={`flush-collapse${index}`} className="my-5">
                    <div id={`heading-${index}`}>
                      <button
                        href=""
                        className="btn btn-link text-left arrow-button large toggleColor p-0 collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        <p className="mb-0 hover-show">{faq.question}</p>
                        <img
                          alt="arrow"
                          className="mx-3"
                          src={arrow}
                          width="36"
                        />
                      </button>
                    </div>
                    <div
                      className=" flex-grow-1 accordion accordion-flush"
                      id={`accordionFlushExample${index}`}
                    >
                      <div className="accordion-item">
                        <div
                          id={`flush-collapse${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading-${index}`}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="pb-2 mt-4">
                            <p
                              className="lead"
                              dangerouslySetInnerHTML={{ __html: faq.content }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Faq
